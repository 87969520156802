import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-landscape-fullscreen';

/**
 * @param  src
 * @param  showVideo
 * @param  isMobile
 * @param  thumb
 */
// eslint-disable-next-line import/prefer-default-export
const VideoThumbnail = ({ src, showVideo, thumb }) => {
  const options = {
    preload: 'none',
    fluid: true,
    controlBar: {
      volumePanel: {
        inline: false,
        volumeControl: {
          vertical: true,
        },
      },
    },
    responsive: true,

    html5: {
      hls: {
        // enableLowInitialPlaylist: true,
        smoothQualityChange: true,
        overrideNative: true,
      },
      vhs: {
        //enableLowInitialPlaylist: true,
        smoothQualityChange: true,
        overrideNative: true,
        bufferLowWaterLine: 30,
        bufferHighWaterLine: 600
      },
    },
  };
  const videoRef = useRef(null);
  const player = useRef(null);

  useEffect(() => {
    if (!player?.curent) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      player.current = videojs(videoRef.current, {
        ...options,
        controls: false,
        autoplay: false,
        sources: [{ src }],
      });
      player.current.landscapeFullscreen({
        fullscreen: {
          enterOnRotate: true,
          alwaysInLandscapeMode: true,
          iOS: true,
        },
      });
      if (thumb) player.current.poster(thumb);
      // videoRef.current.style.opacity = "0";
    }

    // return () => {
    //   if (player?.current !== null) {
    //     player.current.dispose();
    //   }
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showVideo && videoRef?.current) {
      const p = videoRef.current.play();
      if (p !== undefined) p.catch(function(){});
    }
    else if (!showVideo && videoRef?.current) videoRef.current.pause();
  }, [showVideo, videoRef, player]);

  useEffect(() => {
    const playerRef = player.current;
    return () => {
      if (playerRef) {
        playerRef.dispose();
        playerRef.current = null;
      }
    };
  }, [player]);

  return (
      <div data-vjs-player>
        <video ref={videoRef} muted loop className={` ${showVideo ? 'block' : 'hidden'} w-full h-full video-js `} />
      </div>
  );
};

export default VideoThumbnail;
